import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import logo from "../assets/beformed_logo.png";
import { useNavigate } from "react-router-dom";
import { useIsPc, useIsTablet } from "../utils/Responsive";

function Header() {
  const navigate = useNavigate();
  const isPc = useIsPc();
  const isTablet = useIsTablet();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const closeMenu = (event) => {
      if (isMenuOpen && !event.target.closest(`.${styles.menu}`)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", closeMenu);
    return () => document.removeEventListener("mousedown", closeMenu);
  }, [isMenuOpen]);

  return (
    <header className={styles.header}>
      <img
        src={logo}
        alt="Logo"
        className={styles.logo}
        style={{ marginLeft: isPc ? "120px" : isTablet ? "64px" : "32px" }}
        onClick={() => navigate("/")}
      />
      {isPc || isTablet ? (
        <>
          <div className={styles.button_group}>
            <div className={styles.button} onClick={() => navigate("/")}>
              소개
            </div>
            <div
              className={styles.button}
              onClick={() => navigate("/portfolio")}
            >
              포트폴리오
            </div>
          </div>
          <div className={styles.spacer} />
        </>
      ) : (
        <>
          <div className={styles.hamburger} onClick={toggleMenu}>
            &#9776; {/* This is a common icon for hamburger menus */}
          </div>
          {isMenuOpen && (
            <div className={styles.overlay}>
              <div className={styles.menu}>
                <img
                  src={logo}
                  alt="Logo"
                  className={styles.menu_logo}
                  onClick={() => navigate("/")}
                />
                <div className={styles.menu_text} onClick={() => navigate("/")}>
                  소개
                </div>
                <div
                  className={styles.menu_text}
                  onClick={() => navigate("/portfolio")}
                >
                  포트폴리오
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </header>
  );
}

export default Header;

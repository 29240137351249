import styles from "./Home.module.css";
import { useIsPc, useIsTablet } from "../utils/Responsive";
import pc_section1 from "../assets/pc/pc_section1.png";
import pc_section2 from "../assets/pc/pc_section2.png";
import pc_section3 from "../assets/pc/pc_section3.png";
import pc_section4 from "../assets/pc/pc_section4.png";
import pc_section5 from "../assets/pc/pc_section5.png";
import pc_section6 from "../assets/pc/pc_section6.png";
import pc_section7 from "../assets/pc/pc_section7.png";
import tablet_section1 from "../assets/tablet/tablet_section1.png";
import tablet_section2 from "../assets/tablet/tablet_section2.png";
import tablet_section3 from "../assets/tablet/tablet_section3.png";
import tablet_section4 from "../assets/tablet/tablet_section4.png";
import tablet_section5 from "../assets/tablet/tablet_section5.png";
import tablet_section6 from "../assets/tablet/tablet_section6.png";
import tablet_section7 from "../assets/tablet/tablet_section7.png";
import mobile_section1 from "../assets/mobile/mobile_section1.png";
import mobile_section2 from "../assets/mobile/mobile_section2.png";
import mobile_section3 from "../assets/mobile/mobile_section3.png";
import mobile_section4 from "../assets/mobile/mobile_section4.png";
import mobile_section5 from "../assets/mobile/mobile_section5.png";
import mobile_section6 from "../assets/mobile/mobile_section6.png";
import mobile_section7 from "../assets/mobile/mobile_section7.png";

function Home() {
  const isPc = useIsPc();
  const isTablet = useIsTablet();

  return (
    <div className={styles.main}>
      <img
        className={styles.section_image}
        src={isPc ? pc_section1 : isTablet ? tablet_section1 : mobile_section1}
        alt="section1"
      />
      <img
        className={styles.section_image}
        src={isPc ? pc_section2 : isTablet ? tablet_section2 : mobile_section2}
        alt="section2"
      />
      <img
        className={styles.section_image}
        src={isPc ? pc_section3 : isTablet ? tablet_section3 : mobile_section3}
        alt="section3"
      />
      <img
        className={styles.section_image}
        src={isPc ? pc_section4 : isTablet ? tablet_section4 : mobile_section4}
        alt="section4"
      />
      <img
        className={styles.section_image}
        src={isPc ? pc_section5 : isTablet ? tablet_section5 : mobile_section5}
        alt="section5"
      />
      <img
        className={styles.section_image}
        src={isPc ? pc_section6 : isTablet ? tablet_section6 : mobile_section6}
        alt="section6"
      />
      <img
        className={styles.section_image}
        src={isPc ? pc_section7 : isTablet ? tablet_section7 : mobile_section7}
        alt="section7"
      />
    </div>
  );
}

export default Home;

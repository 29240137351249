import React from "react";
import { useMediaQuery } from "react-responsive";

export const useIsPc = () => {
  return useMediaQuery({ minWidth: 1200 });
};

export const useIsTablet = () => {
  return useMediaQuery({
    minWidth: 720,
    maxWidth: 1200,
  });
};

export const useIsMobile = () => {
  return useMediaQuery({
    maxWidth: 720,
  });
};

export const PC = ({ children }) => {
  const isPc = useMediaQuery({
    minWidth: 1024,
  });

  return <>{isPc && children}</>;
};

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({
    minWidth: 393,
    maxWidth: 1024,
  });

  return <>{isTablet && children}</>;
};

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({
    maxWidth: 393,
  });

  return <>{isMobile && children}</>;
};

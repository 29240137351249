import styles from "./Portfolio.module.css";
import Flatground from "../assets/portfolio/Flatground.png";
import IdeationGPT from "../assets/portfolio/IdeationGPT.png";
import IGPT from "../assets/portfolio/I-GPT.png";
import JeonseScanner from "../assets/portfolio/JeonseScanner.png";
import BeformedService from "../assets/portfolio/BeformedService.png";
import StakeSwap from "../assets/portfolio/StakeSwap.png";
import BlackCabinet from "../assets/portfolio/BlackCabinet.png";
import Medibutton from "../assets/portfolio/Medibutton.png";
import { useIsPc, useIsTablet } from "../utils/Responsive";
const projects = [
  {
    id: 1,
    title: "Flatground",
    description:
      "아마존 셀러의 판매 데이터를 AI를 통해 분석 및 해석하고, 매출 향상을 위한 실질적인 행동 계획을 도출하는 지능형 솔루션 구축",
    image: Flatground,
    date: "2023.11",
    period: "3개월",
  },
  {
    id: 2,
    title: "Ideation GPT",
    description:
      "인공지능을 활용하여 비즈니스 아이디어 브레인스토밍 과정에 다양한 관점을 도입, 창의적이고 혁신적인 아이디어 발전을 촉진시키는 솔루션 구축",
    image: IdeationGPT,
    date: "2023.10",
    period: "1개월",
  },
  {
    id: 3,
    title: "I.GPT(Interview GPT)",
    description:
      "인공지능을 활용해 사용자 인터뷰 프로세스를 획기적으로 자동화하여, 전통적 방법에 비해 시간과 비용을 대폭 절감하며 서비스에 대한 실시간 사용자 피드백을 확인할 수 있는 솔루션 구축",
    image: IGPT,
    date: "2023.08",
    period: "2개월",
  },
  {
    id: 4,
    title: "Medibutton",
    description:
      "암 환자들의 의료 정보 접근성 향상을 위해 자연어처리 기술을 활용한 의무기록 번역 서비스 제공",
    image: Medibutton,
    date: "2022.01",
    period: "6개월",
  },
  {
    id: 5,
    title: "Beformed 모바일웹 서비스 개발",
    description:
      "사용자가 원하는 특정 카테고리와 세부 요구사항에 따라 맞춤형 AI 이미지를 제공하는 서비스 구축",
    image: BeformedService,
    date: "2023.05",
    period: "2개월",
  },
  {
    id: 6,
    title: "전세스캐너 모바일 웹 개발",
    description:
      "전세 계약의 전 과정과 이력을 블록체인에 기록함으로써, 모든 정보를 투명하게 공개하고 전세 사기를 예방하는 첨단 솔루션 개발",
    image: JeonseScanner,
    date: "2023.07",
    period: "1개월",
  },
  {
    id: 7,
    title: "Black Cabinet 앱 서비스 개발",
    description:
      "고가의 스포츠 카드에 대한 접근성을 높이고, 콜렉터들이 소유권의 일부를 투자하고 공유할 수 있는 새로운 방식의 플랫폼 개발",
    image: BlackCabinet,
    date: "2023.01",
    period: "4개월",
  },
  {
    id: 8,
    title: "Stakeswap 웹 서비스 개발",
    description:
      "Ethereum의 Shanghai 업그레이드와 함께 도입될 Liquid Staking 메커니즘을 활용하여 사용자에게 추가적인 보상을 제공하는 탈중앙화 거래소(DEX) 구축",
    image: StakeSwap,
    date: "2023.02",
    period: "1개월",
  },
];

function Portfolio() {
  const isPc = useIsPc();
  const isTablet = useIsTablet();
  return (
    <div
      style={{
        padding: isPc ? "120px 180px" : isTablet ? "60px 64px" : "30px 32px",
      }}
    >
      <h1 className={styles.title}>What is our project?</h1>
      <div className={styles.description}>Beformed 포트폴리오</div>
      <div className={styles.card_group}>
        {projects.map((project) => (
          <div
            className={styles.card}
            style={{ width: isPc ? "30%" : isTablet ? "45%" : "100%" }}
            key={project.id}
          >
            <img src={project.image} alt="Project" />
            <h2>{project.title}</h2>
            <p className={styles.card_description}>{project.description}</p>
            <div className={styles.card_date_group}>
              <div>
                구축년도{" "}
                <span style={{ fontWeight: "normal" }}>{project.date}</span>
              </div>
              |
              <div>
                구축기간{" "}
                <span style={{ fontWeight: "normal" }}>{project.period}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Portfolio;
